@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:ital,wght@0,400..700;1,400..700&display=swap');

@font-face {
  font-family: 'Sans-Regular';
  src: 
       url('./fonts/GeneralSans/GeneralSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sans-Medium';
  src: 
       url('./fonts/GeneralSans/GeneralSans-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sans-SemiBold';
  src: 
       url('./fonts/GeneralSans/GeneralSans-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sans-Bold';
  src: 
       url('./fonts/GeneralSans/GeneralSans-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Familjen Grotesk', sans-serif;
}

/* Checkbox buttons */
